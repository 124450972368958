<template>
<div> 
  <!-- select 2 demo -->
  
  <b-modal
      id="modal-login"
     
      ok-title="Add Flavour"
      centered
       ok-only
       hide-footer="true"
       
      
      title="Add Size"
    >
      <b-form>
    
    
        <b-form-group>
          <b-row>
            <b-col cols="3">
           <h5 style="margin-top:10px;"> Size </h5>

                       
            </b-col>
              <b-col>
              <b-form-input
               v-model="myObj.name"
                      placeholder="Enter size here as 12'' (2 - 4 persons)"
                      @focusout="checkTitle()"
                      
                      ref="title"
                     
                    />
              </b-col>
              
          </b-row>
           
        </b-form-group>
       


       
      </b-form>  
            <br>    
               <div style="" >
                <b-button
              class="float-right ml-5"
                  variant="primary"
                  @click="AddSize()"
                >
                  
                  <span class="text-nowrap">Save</span>
                </b-button>
              </div>
            
            
    </b-modal>

  <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

           <!-- Add Flavour -->
          <b-col
            cols="*"
           class="ml-1"
          >
            <div class="d-flex align-items-center justify-content-start">
               <div style="padding-right:15px">
                <b-button
                 @click="OpenAddModal()"
                  variant="primary"
                >
                    <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
                  <span class="text-nowrap">Add Size</span>
                </b-button>
              </div>
             
            </div>
          </b-col>
         
              <b-col cols="6" >

                 <b-form-input
                v-model="searchQuery"
                class="d-inline-block "
                placeholder="Search..."
              />


           </b-col>
          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col> -->
        </b-row>
      </div>


  <div>
    <b-table :items="filterSizes" :fields="fields" responsive striped >
    <template #cell(#)="data">
        {{ data.index + 1 }} 
      </template>
      <template #cell(actions)="data">
            <template >
              <feather-icon
              @click="OpenEditModal(data.item)"
                icon="EditIcon"
                size="16"
                class="align-middle text-body"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.left
                title="Edit size"
              />
              <feather-icon
             @click="DeleteFlavour(data.item.sizeId)"
                icon="DeleteIcon"
                size="16"
                class="ml-2 align-middle text-body"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete size"
              />
            </template>
             
          
          
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item    @click="OpenEditModal(data.item)">
              <feather-icon icon="EditIcon" />
              
              <span class="align-middle ml-50"   >Edit</span>
            </b-dropdown-item>
              <b-dropdown-item    @click="DeleteFlavour(data.item.sizeId)">
              <feather-icon icon="XIcon" />
              
              <span class="align-middle ml-50"   >Delete</span>
            </b-dropdown-item>
             
             <b-dropdown-item @click="AcceptOrder(data.item.orderId)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50" >Accept order</span>
            </b-dropdown-item>

            <b-dropdown-item @click="DenyOrder(data.item.orderId)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Decline order</span>
            </b-dropdown-item> 
          </b-dropdown> -->
        </template>
    </b-table>
<div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="10"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                  @click="PageChange"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

  </div>
  </b-card>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BImg,VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from 'vue-flatpickr-component'
import { BFormSelect } from 'bootstrap-vue'
//import Multiselect from "vue-multiselect";
import VueGallery from 'vue-gallery';

export default {
  components: {
    //Multiselect,
    
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    //BTooltip,
    //BImg ,
    //BMedia,
    //BAvatar,
    //BLink,
    //BBadge,
   // BDropdown,
    //BDropdownItem,
    BPagination,
   // vSelect,
    //flatPickr,
//'gallery': VueGallery
  },
    directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
   props: {
   /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
   },
created()
{
 this.LoadData();
},
computed:
{
 PageChange()
{
  console.log(this.currentPage);
 return this.LoadData();
},
filterSizes:function()
    {
      return this.items.filter((pro)=>
      {
      return  pro.name.toLowerCase().match(this.searchQuery.toLowerCase())
      } )
    }
},
  data(){
    return {
      searchQuery:'',
      currentPage:1,
      totalRows:1,
      errors:
       {
         status :false,
         
       },
      statusOptions:["Active","Inactive"],
      filterData:'',
      date:{
        range:'',
      },
      index:null,
      cover:'',
      docs:[],
      partners:[],
      fields:['#',{label:'Size',key:'name'}  ,'actions' ],
      items:[],
      selected: null,
        filterOptions: [
          { text: 'Most sold product', value: 'mostSold' },
          { text: 'Least sold product', value: 'leastSold' },
          { text: 'Most popular product', value: 'mostPopular' },
          { text: 'Least popular product', value: 'leastPopular' },
          { text: 'Most profitable product', value: 'mostProfitable' },
        ],
       rangeDate: null,
        myObj: {
        sizeId:0,
        vendorId:this.$store.state.userData.userID,
        name:"",
        status:""
      },
  detailObj:{},
  }
  
  },
  methods: 
  {
   
checkTitle()
{
 var elem = this.$refs["title"] 
 return  elem.state = this.myObj.name.length > 2 ? true : false
},
checkStatus()
{
              if( this.myObj.status == "" )
              {
               return this.errors.status = true;  
              }  
              else
              {
                  return this.errors.status = false;  
              }
},
  OpenAddModal()
  {
    this.$bvModal.show("modal-login");
    this.myObj.sizeId = 0;
    this.myObj.vendorId =  this.$store.state.userData.userID;   
    this.myObj.name = "";
   

},
OpenEditModal(row)
  {
    this.$bvModal.show("modal-login");
    this.myObj.sizeId = row.sizeId;
    this.myObj.vendorId = row.vendorId;   
    this.myObj.name = row.name;
  
},
DeleteFlavour(id)
{
Swal.fire({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, Delete it!'
}).then((result) => {
  if (result.isConfirmed) {
 var axios = require('axios');
  var config = {
    method: 'delete',
    url: 'https://okaaik.fastech.pk/api/sizes/Delete/'+ id  ,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };
 console.log(config.url);
  axios(config)
  .then( (response) => {
     console.log(JSON.stringify(response.data));
     if(response.data.status === "success")
     {
      Swal.fire(
      'Success!',
      'Your size has been deleted.',
      'success'
    ).then((res)=>{

      this.LoadData();
    })
     }
 })
  .catch(function (error) {
    console.log(error);
  });


   
  }
})
 

},
  AddSize()
  {
  
    if(this.checkTitle() == false  )
    {
          return  this.$bvToast.toast('Please fill the form correctly.', {
            title: "Error!",
            variant: 'danger',
            toaster:  'b-toaster-bottom-center',
      })
    }
    else
    {
     console.log(this.myObj);
    if(this.myObj.sizeId == 0)
    {
    var axios = require('axios');
    // this.$store.state.userData.userID
      var config = {
        method: 'post',
        url: 'https://okaaik.fastech.pk/api/sizes/Post/',
        headers: {
          'Authorization': 'bearer T6O1i268ccP1C_94E49EDA_4sATCbEdIPthcLv1QyEVpyyvnTBzJ-6c79dAr1M9IeDV5gcBQhluDPPxmKok0XNUf5S5nM4jPvUGlyoeglhPvGKjJNaLSe6Kz2kLwUn0VML_mLWcHBtz5cBWqpipD_TiMT1_bEFG-p9i4gswBn0GIrKtX4QMSSa9O82E5qrKwOcEV2hR4Y-FJADFticsWbP2wrQQfK6EPbc1Y8GTwBchu7-HrMCHyJNO06BpWoUB2lUkGktZuqsmEeRSgrDc8fAuQjbqpwUmAD_KYCVwK7avahTN6MVGIVCgu5u54cG-4'
        },
        data : this.myObj
      };

      axios(config)
      .then( (response) => {
        if(response.data.status === "success")
        {
        console.log(response.data);
         this.$bvModal.hide("modal-login");
        this.LoadData();

            this.$bvToast.toast('Filling added.', {
            title: "Success!",
            variant: 'success',
            toaster:  'b-toaster-bottom-center',
         
          
      })
        }
      
        //  this.data  =  response.data;
        //console.log(this.$store.state.userData.userID);
      //this.items = response.data;

       // console.log(this.items);
    })
      .catch(function (error) {
        console.log(error);
      });

    }
    else
    {
      //Edit
      var axios = require('axios');
    // this.$store.state.userData.userID
      var config = {
        method: 'put',
        url: 'https://okaaik.fastech.pk/api/sizes/Put/'+ this.myObj.sizeId,
        headers: {
          'Authorization': 'bearer T6O1i268ccP1C_94E49EDA_4sATCbEdIPthcLv1QyEVpyyvnTBzJ-6c79dAr1M9IeDV5gcBQhluDPPxmKok0XNUf5S5nM4jPvUGlyoeglhPvGKjJNaLSe6Kz2kLwUn0VML_mLWcHBtz5cBWqpipD_TiMT1_bEFG-p9i4gswBn0GIrKtX4QMSSa9O82E5qrKwOcEV2hR4Y-FJADFticsWbP2wrQQfK6EPbc1Y8GTwBchu7-HrMCHyJNO06BpWoUB2lUkGktZuqsmEeRSgrDc8fAuQjbqpwUmAD_KYCVwK7avahTN6MVGIVCgu5u54cG-4'
        },
        data : this.myObj
      };

      axios(config)
      .then( (response) => {
        if(response.data.status === "success")
        {
        this.$bvModal.hide("modal-login");
        console.log(response.data);
        this.LoadData();

            this.$bvToast.toast('size updated.', {
            title: "Success!",
            variant: 'success',
            toaster:  'b-toaster-bottom-center',
         
          
      })
        }
        else
        {
            this.$bvToast.toast('Something went wrong.', {
            title: "Error!",
            variant: 'danger',
            toaster:  'b-toaster-bottom-center',
         
          
      })
        }
      
        //  this.data  =  response.data;
        //console.log(this.$store.state.userData.userID);
      //this.items = response.data;

       // console.log(this.items);
    })
      .catch(function (error) {
        console.log(error);
      });

    }
    }

  
  },
   
   



  LoadData() {
  var axios = require('axios');
// this.$store.state.userData.userID
  var config = {
    
    method: 'get',
    url: 'https://okaaik.fastech.pk/api/sizes/GetAll/?id='+ this.$store.state.userData.userID  + '&pageNo=' + this.currentPage,
    headers: {
      'Authorization': 'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI'
    }
  };

  axios(config)
  .then( (response) => {
     //console.log(JSON.stringify(response.data));
   
    //  this.data  =  response.data;
     //console.log(this.$store.state.userData.userID);
   this.items = response.data.sizes;
   this.totalRows = response.data.totalRows;

     console.log(this.items);
 })
  .catch(function (error) {
    console.log(error);
  });
    },  
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
</style> 